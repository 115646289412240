<template>
  <layout-user-dashboard>
    <template #default>
      <h1 class="margin-s-bottom">{{ tt("userDashboard.orders.pageTitle") }}</h1>
      <p class="text-l margin-max-bottom">
        {{ tu("userDashboard.orders.introText") }}
      </p>


      <custom-crud-list v-bind="listConfiguration">
        <template #cell-createdAt="{ item }">
          <span>{{ translateDate(item.createdAt, "dateWithTime") }}</span>
        </template>
        <template #cell-totalWithTax="{ item }">
          <span>{{
              translateNumber(item.Quote.grandTotal, "currency", {
                currency: item.Quote.currency,
              })
            }}</span>
        </template>
        <template #cell-numProducts="{ item }">
          <span>{{ translateNumber(item.Quote.totalProductCount) }}</span>
        </template>
        <template #cell-vendors="{ item }">
          <span v-if="item.Vendors && item.Vendors.length > 0">
            {{ item.Vendors.map((vendor) => vendor.name).join(", ") }}
          </span>
          <span v-else>-</span>
        </template>
        <template #cell-buyer="{ item }">
          <span v-if="item?.Buyer?.email">{{ item.Buyer.email }}</span>
          <span v-else>-</span>
        </template>
        <template #cell-payments="{ item }">
          <span v-if="Array.isArray(item.Payments) && item.Payments.length > 0">
            {{
              translate(
                "userDashboard.orders.orderPaymentSummaryText",
                getPaymentSummaryForOrder(item)
              )
            }}
          </span>
          <span v-else>
            {{ translate("userDashboard.orders.paymentStatus.noPayments") }}
          </span>
        </template>
      </custom-crud-list>
    </template>
  </layout-user-dashboard>
</template>

<script>
import { computed } from "vue";
import CustomCrudList from "@/client/components/crud/customV1/List.vue";

export default {
  layout: {
    component: "default",
    params: {
      title: "userDashboard.orders.pageTitle",
      subTitle: "userDashboard.orders.subTitle",
      containerClass: "margin-top-top margin-bottom-top padding-remove"
    },
  },
  components: {
    CustomCrudList
  },
  middleware: {
    requireUser: {},
  },
  data: function () {
    return {
      listConfiguration: {
        subject: "order",
        listSrc: "ecommerce/order",
        initialOrdering: { key: "createdAt", direction: "desc" },
        showIdField: true, // bool deafult true
        sortable: false, //bool, default false
        publishable: false, //bool, default false
        enableMassActions: false,
        editRoutePrefix: "admin-", //**
        createRoutePrefix: "admin-",//**
        deleteRoutePrefix: "ecommerce/",//**
        additionalListData: {
          simplified: false,
          translate: false,
        },
        constantFilters: {
          OwnerId: computed(() => this.$store.getters['user/profile']?.id)
        },
        fields: {
          status: {
            itemKey: "status",
            label: "userDashboard.orders.fields.status",
            orderable: false,
          },
          createdAt: {
            itemKey: "createdAt",
            label: "userDashboard.orders.fields.createdAt",
          },
          totalWithTax: {
            label: "userDashboard.orders.fields.totalWithTax",
            orderable: false,
          },
          numProducts: {
            label: "userDashboard.orders.fields.numProducts",
            orderable: false,
          },
          vendors: {
            label: "userDashboard.orders.fields.vendors",
            orderable: false,
          },
          payments: {
            label: "userDashboard.orders.fields.payment",
            orderable: false,
            filterable: false,
          },
        },
        actions: false,
      },
    };
  },
  methods: {
    getPaymentSummaryForOrder(order) {
      let res = {
        total: 0,
        count: 0,
        status: "-",
      };
      if (!order.Payments || order.Payments.length < 1) {
        return res;
      }
      res.status = this.t("userDashboard.orders.paymentStatus.complete");
      order.Payments.forEach((payment) => {
        res.total = res.total + payment.amount;

        if (payment.status !== "completed") {
          res.status = this.t("userDashboard.orders.paymentStatus.incomplete");
        }
      });

      res.total = this.translateNumber(res.total, "currency", {
        currency: order.Quote.currency,
      });
      return res;
    },
  },
  meta: "userDashboard.orders.browserTitle",
};
</script>

<style scoped lang="scss"></style>
